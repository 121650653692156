.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; /* Orqa fon rangi */
}

.content {
    text-align: center;
    background-color: #fff; /* Konteynerning orqa fon rangi */
    border-radius: 10px; /* Burchaklarni yumshatish */
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soqov ko‘rinish */
}

h1 {
    color: #333; /* Sarlavha rangi */
}

.links {
    display: flex;
    flex-direction: column; /* Vertikal joylashish */
    gap: 15px; /* Linklar orasidagi masofa */
    margin-top: 20px; /* Sarlavha va linklar o‘rtasidagi masofa */
}

.admin, .kanal {
    display: flex;
    align-items: center; /* Icon va matnni bir qatorga joylash */
    text-decoration: none; /* Undan keyin chizish */
    color: #007bff; /* Link rangi */
    padding: 10px;
    border: 1px solid #007bff; /* Chiziq rangi */
    border-radius: 5px; /* Burchaklarni yumshatish */
    transition: background-color 0.3s, color 0.3s; /* O‘zgarish animatsiyasi */
}

.admin:hover, .kanal:hover {
    background-color: #007bff; /* Hoverda orqa fon rangi */
    color: white; /* Hoverda matn rangi */
}

.icon {
    margin-right: 8px; /* Icon va matn orasidagi masofa */
}
